<template>
	<div class="wrc">
		<div class="title title-vertical flex-space-between">
			<span>图片锦集</span>
		</div>
		<div class="wrc-content flex-wrap">
			<div class="item flex-direction-column" v-for="(item, index) in list" :key="item.id" @click="itemClick(item)">
				<img class="pic" :src="item.image"/>
				<span class="one-line">{{ item.name }}</span>
				<div class="desc flex">
					<img src="@/assets/image/icon/10.png"/>
					<span>{{ item.create_time }}</span>
				</div>
				<div class="line" v-if="(index + 1) % 3 == 0 && list.length > 3 && index != 5"></div>
			</div>
		</div>
		<Lack
			v-if="list.length == 0"
			text="暂无内容"
			minHeight="260px"
			imgWidth="102px"
			imgHeight="68px"
			:imgSrc="require('@/assets/image/img/15.png')"
		></Lack>
		<div class="pagination">
			<el-pagination
				@current-change="currentChange"
				background
				layout="prev, pager, next"
				:page-size="6"
				:total="list.length"
			>
			</el-pagination>
		</div>
	</div>
</template>

<script>
import Lack from '@/components/lack/lack.vue'
import {postAlbum} from '@/service/api/index'

export default {
	components: {Lack},
	data() {
		return {
			list: []
		}
	},

	mounted() {
		this.postAlbum(1)
	},

	methods: {
		postAlbum(page) {
			postAlbum({page}).then((res) => {
				this.list = Object.freeze(res.msg.data)
			})
		},
		itemClick(item) {
			this.$router.push({
				name: 'pdetail',
				params: {
					info: item
				}
			})
		},
		currentChange(page) {
			this.postAlbum(page)
		}
	}
}
</script>

<style lang="less" scoped>
/deep/ .el-pager li {
	font-weight: normal;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
	background-color: #e50114;
	color: #fff;
}

.pagination {
	display: flex;
	justify-content: center;
	margin-top: 29px;
}

@import url('~@/assets/css/pictureANDvideo.less');
</style>
